/*

████████╗██╗  ██╗███████╗███╗   ███╗███████╗███████╗
╚══██╔══╝██║  ██║██╔════╝████╗ ████║██╔════╝██╔════╝
   ██║   ███████║█████╗  ██╔████╔██║█████╗  ███████╗
   ██║   ██╔══██║██╔══╝  ██║╚██╔╝██║██╔══╝  ╚════██║
   ██║   ██║  ██║███████╗██║ ╚═╝ ██║███████╗███████║
   ╚═╝   ╚═╝  ╚═╝╚══════╝╚═╝     ╚═╝╚══════╝╚══════╝

*/

/*******************************
        Theme Selection
*******************************/

/* To override a theme for an individual element
   specify theme name below
*/

/* Global */
@site: "default";
@reset: "default";

/* Elements */
@button: "default";
@container: "default";
@divider: "default";
@flag: "default";
@header: "default";
@icon: "default";
@image: "default";
@input: "default";
@label: "default";
@list: "default";
@loader: "default";
@placeholder: "default";
@rail: "default";
@reveal: "default";
@segment: "default";
@step: "default";

/* Collections */
@breadcrumb: "default";
@form: "default";
@grid: "default";
@menu: "default";
@message: "default";
@table: "default";

/* Modules */
@accordion: "default";
@checkbox: "default";
@dimmer: "default";
@dropdown: "default";
@embed: "default";
@modal: "default";
@nag: "default";
@popup: "default";
@progress: "default";
@rating: "default";
@search: "default";
@shape: "default";
@sidebar: "default";
@sticky: "default";
@tab: "default";
@transition: "default";

/* Views */
@ad: "default";
@card: "default";
@comment: "default";
@feed: "default";
@item: "default";
@statistic: "default";

/*******************************
            Folders
*******************************/

/* Path to theme packages */
@themesFolder: "themes";

/* Path to site override folder */
@siteFolder: "site";

/*******************************
         Import Theme
*******************************/

@import (multiple) "~semantic-ui-less/theme.less";
@fontPath: "../../../themes/@{theme}/assets/fonts";

/* End Config */
@importGoogleFonts: false;
@black: #3d434c;
@green: #3c8663;
@red: #e00f13;
@textColor: #4a4f59;

@fontSize: 16px;
@primary: @red;
@border: none;
@boxShadow: none;
@headerFont: "Noto Sans SC", sans-serif;
@lineHeight: 1.8em;
@focusedFormBorderColor: @black;
@focusedFormMutedBorderColor: @black;

/*
* tabs
*/
.ui.tabular.menu {
	justify-content: space-between !important; // evenly distribute
}
.ui.tabular.menu ::after {
	content: unset; // evenly distribute
}
.ui.tabular.menu .active.item {
	border-bottom: none !important; // show empty space below active menu
}

/*
* dropdown
*/
.ui.dropdown .menu > .item:hover {
	background-color: lighten(@primary, 50) !important;
}
.ui.dropdown .menu .selected.item {
	background-color: lighten(@primary, 45) !important;
}

/*
* dimmer
*/
.ui.dimmer {
	background-color: unset !important;
}
.ui.dimmer .ui.loader:after {
	border-color: @black transparent transparent;
}

/*
* menu
*/
.ui.menu {
	padding: 0 3em;
}
@media only screen and (max-width: 1200px) {
	.ui.menu {
		padding: 0 2em;
	}
}
.ui.menu .item:hover {
	background-color: lighten(@primary, 50) !important;
}
.ui.menu .active.item {
	background-color: unset;
	border-bottom: 3px solid lighten(@primary, 30) !important;
}
.ui.menu a {
	color: #4a4f59;
}

/*
* cards
*/
.ui.centered.cards {
	justify-content: space-around;
	padding: 0 3em;
}
.ui.cards:after,
.ui.card:after {
	content: unset;
}
.ui.cards > .card > .content,
.ui.card > .content {
	border-top: none;
}

/*
* statistic
*/
.ui.statistic > .label {
	text-transform: unset !important;
}
.ui[class*="right floated"].statistic {
	margin: 0 0 0 1em !important;
}

/*
* pagination
*/
.ui.menu.pagination {
	border-bottom: none !important;
}
.ui.menu.pagination .active.item {
	background-color: @black !important;
	color: @white !important;
	border-radius: 6px !important;
}
.ui.menu.pagination .active.item:hover {
	background-color: @black !important;
}
.ui.menu.pagination .item:hover {
	border-radius: 6px !important;
	background-color: lighten(@primary, 50) !important;
}

/*
* divider
*/
.ui.divider {
	letter-spacing: unset !important;
	font-weight: unset !important;
	color: unset !important;
}
.ui.vertical.divider {
	height: 35% !important;
}

/*
* form
*/
.ui.form .field > label {
	font-size: unset !important;
	font-weight: unset !important;
}

/*
* segment
*/
.ui.segment {
	padding: 0 3em;
}
.ui.segment.left-padded {
	padding-left: 3em !important;
}
.ui.attached.segment {
	border: unset !important;
}

@media (max-width: 668px) {
	.row {
		padding: 0.5em !important;
	}

	.column {
		padding: 0em !important;
	}
}
